.article-content {
  margin-right: 45px;

  img {
    max-width: 100%;
    height: auto;
  }

  h2,
  h3,
  h4,
  h5 {
    font-size: 24px;
    line-height: 28px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.29px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  a {
    color: #37bc98;
    font-weight: 500;
    text-decoration: none;
  }

  a:hover {
    color: #34a788;
    text-decoration: underline;
  }

  blockquote {
    margin: 0;
    padding: 0 20px;
    border-left: 3px solid #ccc;
    font-size: 18px;
    line-height: 28px;
  }

  figure {
    margin: 0;
  }

  figcaption {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #666;
  }

  hr {
    border: 0.5px solid #ccc;
  }

}
